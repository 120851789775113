import React from "react";
import FacialRecognitionApp from "./FacialRecognitionApp";

function App() {
  return (
    <div className="App">
      <FacialRecognitionApp />
    </div>
  );
}

export default App;
